<template>
  <div>
    <title-bar :title="$t('navigations.partners')"/>
    <div v-if="partners" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('partner.new')"
        :addBtnLink="$helper.getAddUrl('partner')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultperPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="partners"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:id="data">
          <router-link :to="$helper.getEditUrl('partner', data.value.id)">
            {{
              (data.value.givenName && data.value.familyName) ? `${data.value.givenName} ${data.value.familyName}` : ''
            }}
            <br v-if="data.value.givenName && data.value.familyName"/>
            {{ data.value.companyName }}
          </router-link>
        </template>
        <template v-slot:lastLogin="data">
          {{ $helper.formatDate(data.value.lastLogin) }}
        </template>
        <template v-slot:sold="data">
          {{ data.value.soldVoucherCount }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('partner', data.value.id)"/>
            <delete-btn @pressDelete="deletePartner(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-partner="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      partners: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t('partner.name'),
          field: "id",
          sortable: false,
        },
        {
          label: this.$t('partner.lastLogin'),
          field: "lastLogin",
          sortable: false,
        },
        {
          label: this.$t('partner.sold'),
          field: "sold",
          sortable: false,
        },
        {
          label: this.$t('partner.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadPartners();
  },
  methods: {
    loadPartners() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: 'ROLE_PARTNER',
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Users.getCollection({params}, "partner_list").then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.partners = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadPartners();
    },
    deletePartner(url) {
      this.deleteByUrl(this.$Users, url, this.$t('partner.deleted'), null, this.loadPartners, this.error);
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadPartners();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadPartners();
    }
  },
};
</script>
